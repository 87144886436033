.init-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
  background-image: url(../../images/backgroundBW.jpg) !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  gap: 1rem;
}

.language-switch {
  position: absolute;
  top: 20px;
  right: 20px;
}

.logo-container {
  text-align: center;
}

.info-panorama {
  padding: 0px 15% 0px 15%;
  margin-bottom: 0;
}

.icons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  justify-content: space-between;
}

.icons-container a {
  color: gray !important;
  font-size: 1rem;
  text-decoration: none !important;
  border: none;
  background: transparent;
}

.link {
  font-weight: 500;
  color: #000 !important;
  text-decoration: none;
  background-color: #e3a22e;
  border-radius: 23px;
  padding: 15px;
}

@keyframes elementor-animation-pulse {
  25% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(0.9);
  }
}

.link:hover {
  animation-name: elementor-animation-pulse;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.social-link {
  font-size: 28px;
}

.social-link:hover {
  fill: #261692;
}

.learnmore{
  text-decoration: none !important;
}

.welcome-init {
  color: #1d135a;
  font-family: "Quicksand", Sans-serif;
  text-decoration: none;
  font-size: 41px;
  font-weight: 600;
  line-height: 1.4em;
  margin: 0;
}

.logo-panorama {
  width: 50vw;
  height: auto;
  padding-top: 8%;
}

.web-icon {
  width: 30px;
  height: 25px;
}

.web-icon:hover {
  filter: brightness(0) saturate(100%) invert(15%) sepia(77%) saturate(3120%)
    hue-rotate(243deg) brightness(77%) contrast(115%);
}
