/* FOOTER */
.footer {
  margin-top: auto;
  background-color: white;
  display: flex;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100%;
}

.logoFooter {
  height: 80px;
  padding: 1rem;
}

.footer a {
  color: gray !important;
  font-size: 1rem;
  text-decoration: none !important;
  border: none;
  background: transparent;
} 

.reserved {
  color: #6c757d !important;
  font-family: "Quicksand", Sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 1rem;
}

@media (max-width: 500px) {
  .footer a {
    font-size: 0.8rem;
  }
  .reserved {
    font-size: 0.8rem;
  }
}

@media (max-width: 435px) {
  .footer a {
    font-size: 0.6rem;
  }
  .reserved {
    font-size: 0.6rem;
  }
}

