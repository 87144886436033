/* HEADER */

.menu-title {
    padding: 0;
    display: inline-block;
    width: 100%;
    border: 0;
    border-bottom-width: 1px;
    color: gray;
    font-size: 16px;
}

.logoPanorama {
    height: 80px;
    padding: 1rem;
}

.navbar-collapse {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 8px;
    padding: 0.5rem;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.dropdown-menu {
    padding: 0.5rem;
}

.dropdown-item {
    color: #4169e1;
    padding: 0 10px;
    cursor: pointer;
}

.btn:hover {
    font-weight: bold;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    color: #444 !important;
    border: none !important;
}

.nav-link:disabled {
    color: #444 !important;
    border: none !important;
}

.submenu-responsive {
    position: unset;
}

.signOut {
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: transparent !important;
    font-weight: bold;
}

.icon {
    position: absolute;
    margin-top: 16px;
}

@media (min-width: 528px) {
    .signOut {
        cursor: pointer;
    }

    .content-header {
        align-items: center;
    }

    .icon::after {
        width: 35px;
        height: 35px;
        fill: currentColor;
        display: inline-block;
        vertical-align: middle;
    }
}

@media (max-width: 528px) {
    .menu-title {
        display: flex !important;
        justify-content: flex-end !important;
        width: 100%;
    }
}

.menu-responsive {
    display: flex;
    width: 35%;
}

.title-menu-responsive {
    padding: 0 12px;
}

.title-menu-responsive:hover {
    font-weight: bold;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    border: none !important;
}

.btn-banderas {
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
}

.btn-banderas-resp {
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
}

a {
    color: gray !important;
    font-size: 16px;
    text-decoration: none !important;
    border: none;
    background: transparent;
}
