@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");

body {
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  font-family: "Quicksand", Sans-serif !important;
  height: 100vh;
}

/* CONTENT */
.containerApp {
  background-color: #f6f6f6;
  display: flex;
  padding: 0 0.5rem;
  width: 100%;
  height: calc(100vh - 162px);
  align-items: center;
  overflow: auto;
}

.home {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem 0 0.5rem;
  height: calc(100vh - 161px);
}

.dashboard {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-end;
  align-content: center;
  overflow: hidden;
  padding: 0 0.5rem 0 0.5rem;
  height: calc(100vh - 161px);
}

.quicksight-embedding-iframe {
  height: calc(100vh - 162px);
}

.framesContainer {
  width: 100%;
  border: 1px solid var(--tabs-border-color);
}

/* HEADER */
.header,
.menu {
  background-color: white;
  width: 100%;
}

.content-header,
.content-menu {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
}