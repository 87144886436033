[data-amplify-authenticator] {
  padding-top: 8rem !important;
  margin-bottom: 2rem;
  background-image: url(../../images/logo.png) !important;
  background-size: 255px 115px !important;
  background-repeat: no-repeat !important;
  background-position: top !important;
  width: auto !important;
  height: auto !important;
}

[data-amplify-authenticator] [data-amplify-form] {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: #eeeeee !important;
  box-shadow: 0 0 40px 0 #000 !important;
  padding: 1rem;
  border: none;
}

[data-amplify-authenticator] [data-amplify-footer] {
  background-color: #eeeeee !important;
  /* box-shadow: 7px 13px 37px #000 !important; */
  box-shadow: 0 0 40px 0 #000 !important;
}

.amplify-tabs__panel--active {
  padding: 0;
  margin: 0;
}

.chk-terms {
  font-size: 14px;
  color: #0073e6 !important;
  border: none;
  padding: 0;
}

.chk-terms:hover {
  text-decoration: underline !important;
}

.chk-terms:focus-visible {
  outline: none;
}

.modal-content {
  border-radius: 5px !important;
  padding-bottom: 10px;
  background-color: #f6f6f6 !important;
}

.modal-header {
  background-color: #f6f6f6 !important;
  border-bottom: none !important;
  padding-bottom: 10px;
}

.modal-body {
  padding: 0 !important;
}

.input-chk {
  display: flex;
  width: auto;
  flex-direction: row;
  align-items: center;
}

.amplify-checkboxfield {
  width: auto !important;
  margin-right: 8px;
}

.amplify-flex .amplify-checkbox {
  width: auto !important;
}

.amplify-checkbox__button {
  width: auto !important;
  display: flex;
  /* justify-content: flex-start !important;
  flex-direction: row !important; */
}

.amplify-field__error-message {
  padding: 0;
  margin: 0 !important;
}

.error-message {
  display: flex;
  justify-content: center;
  font-size: 15px;
  color: red;
}

.message-complete {
  display: flex;
  justify-content: center;
  font-size: 15px;
  color: green;
}

.amplify-flex {
  width: 100%;
}

.amplify-textfield {
  gap: 0 !important;
}

.amplify-input {
  font-size: 13px !important;
  padding: 0.4rem !important;
}

.amplify-label {
  font-size: 13px;
}
