@keyframes wipe-in-right {
  from {
    clip-path: inset(0 100% 0 0);
  }

  to {
    clip-path: inset(0 0 0 0);
  }
}

.tab-container.open {
  animation: 1.5s cubic-bezier(.25, 1, .30, 1) wipe-in-right both;
}

@keyframes wipe-out-left {
  from {
    clip-path: inset(0 0 0 0);
  }

  to {
    clip-path: inset(0 100% 0 0);
  }
}

.tab-container.close {
  animation: 1.5s cubic-bezier(.25, 1, .30, 1) wipe-out-left both;
}

.content-tabs {
  flex-direction: column;
  height: calc(100vh - 162px);
  width: 29px;
  z-index: 900;
}

.buttonMenu {
  padding: 0;
  margin-top: -10px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  background: transparent;
}

.buttonMenu:hover {
  color: #023b9c !important;
}

.selected {
  background-color: #133979 !important;
}

.tab-container {
  width: 200px;
  height: calc(100vh - 162px);
  display: inline-block;
  left: 36px;
  top: 79px;
  position: absolute;
  transition: 0.5s ease;
  overflow-y: auto;
}

.tab {
  padding: 0;
  margin: 0;
  display: flex;
  white-space: nowrap;
  /* text-overflow: ellipsis;
  overflow: hidden; */
}

.tab a {
  font-size: 0.9rem;
  text-overflow: ellipsis;
  text-decoration: none;
  overflow: hidden;
  height: auto;
  width: 200px;
  background-color: #062B60;
  border-bottom: 1px solid white;
  padding: 5px;
  display: inline-block;
  color: white !important;
  font-weight: 300;
  overflow-wrap: break-word;
  cursor: pointer;
  white-space: normal !important;
}

.tab>a:hover {
  background-color: #133979;
}

.sidebar {
  background: transparent;
  position: fixed;
  transform: rotate(-90deg);
  border: none;
  color: grey;
  margin-top: 36px;
  margin-left: -28px;
}